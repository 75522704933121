.form {
	&__label {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;
		margin-bottom: 4px;
		display: inline-block;
	}

	&__block {
		background: #ffffff;
		border: 1px solid #d8dcdf;
		border-radius: 4px;
		padding: 10px 16px;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: -0.02em;
		color: #98a1aa;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		gap: 8px;

		&:not(:placeholder-shown) {
			color: #121f33;
		}
	}

	&__input {
		border: none;
		width: 100%;
	}

	&__input:focus {
		outline: none;
	}

	&__block:focus-within {
		border: 1px solid #4a93d6;
		color: #121f33;
		outline: none;
		-webkit-box-shadow: 0px 0px 0px 2px rgb(227, 239, 250);
		-moz-box-shadow: 0px 0px 0px 2px rgb(227, 239, 250);
		box-shadow: 0px 0px 0px 2px rgb(227, 239, 250);
	}

	&__chip {
		padding: 1px 7px;
		background: #ffffff;
		border: 1px solid #d8dcdf;
		border-radius: 2px;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;
		display: inline-flex;
		align-items: center;
	}

	&__remove {
		background: none;
		border: none;
		padding: 0;
		cursor: pointer;
		margin-left: 4px;
		height: 16px;

		& i {
			color: #98a1aa;
			font-size: 16px;
			line-height: 16px;
		}
	}
}
