.customSelect {
	min-width: 56px;
	position: relative;
	width: 100%;
	margin-bottom: 12px;

	&__label {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;
		margin-bottom: 4px;
		display: inline-block;
	}

	&__toggle {
		background: #ffffff;
		border: 1px solid #d8dcdf;
		box-sizing: border-box;
		border-radius: 4px;
		padding: 10px 16px;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: -0.02em;
		color: #98a1aa;
		text-align: left;
		position: relative;
		min-width: 56px;
		width: 100%;
		padding-right: 32px;
		height: 40px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		&Disabled {
			background: rgba(0, 0, 0, 0.08);
			cursor: default;
			pointer-events: none;
		}

		&:after {
			content: '\e91c';
			position: absolute;
			top: 10px;
			color: #98a1aa;
			font-size: 10px;
			right: 12px;
			width: 16px;
			height: 16px;
			text-align: center;
			background: #f8f9fb;
			border-radius: 4px;
			font-family: 'icomoon' !important;
		}
	}

	&__hasValue {
		color: #121f33;
	}

	&__hasIcon {
		padding: 7px 16px;
	}

	&__active {
		border: 1px solid #4a93d6;
		outline: none;
		-webkit-box-shadow: 0px 0px 0px 2px rgb(227, 239, 250);
		-moz-box-shadow: 0px 0px 0px 2px rgb(227, 239, 250);
		box-shadow: 0px 0px 0px 2px rgb(227, 239, 250);

		&:after {
			transform: rotate(180deg);
		}
	}

	&__searchWrap {
		position: relative;
		&:after {
			content: '\e90c';
			position: absolute;
			top: 8px;
			color: #98a1aa;
			font-size: 24px;
			right: 11px;
			font-family: 'icomoon' !important;
		}
	}

	&__drop {
		position: absolute;
		top: calc(100% + 4px);
		left: 0;
		background: #fff;
		z-index: 10;
		display: none;
		width: 100%;
		border: 1px solid #d8dcdf;
		border-radius: 4px;
		-webkit-transform: translate3d(0, 0, 1px);
		transform: translate3d(0, 0, 1px);
	}

	&__list {
		padding: 0;
		margin: 0;
		list-style: none;
		max-height: 168px;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 4px;
			height: 8px;
			background-color: none;
		}

		&::-webkit-scrollbar-thumb {
			background: #98a1aa;
			border-radius: 4px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background-color: #98a1aa;
		}
	}

	&__search {
		background: #ffffff;
		border: none;
		border-bottom: 1px solid #d8dcdf;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		padding: 10px 40px 10px 16px;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #6b7881;
		display: block;
		width: 100%;
		outline: none;
	}

	&__item {
		padding: 4px 8px;
		background: #fff;
	}

	&__icon {
		margin-right: 4px;
		vertical-align: middle;
		max-width: 24px;
	}

	&__btn {
		border: none;
		margin: 0;
		display: block;
		background: none;
		font-weight: 400;
		padding: 6px;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #121f33;
		display: flex;
		align-items: center;
		border-radius: 4px;
		width: 100%;
		text-align: left;

		&Selected {
			background: #e9f2fb;
		}

		&:hover {
			background: #f8f9fb;
		}
	}

	&__loader {
		height: 40px;
		width: 100%;
		background: #f8f9fb;
		border: 1px solid #d8dcdf;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
	}

	&__error {
		border: 1px solid #de4548;
	}
}
.error {
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	display: flex;
	align-items: center;
	color: #de4548;
}

.loading {
	pointer-events: none;
	user-select: none;
}

.open {
	display: block;
}
