@import 'src/styles/utils/include-media';
.wrapper {
	padding: 32px 16px;
	margin: 0 auto;

	@include media('>=tablet') {
		max-width: 352px;
	}
	@include media('>=desktop') {
		max-width: 360px;
		padding: 32px 0;
		margin: 0 auto;
	}
}
.logo {
	margin: 0 auto 32px;
	display: block;
}
.title {
	margin-top: 0;
	margin-bottom: 8px;
	letter-spacing: -0.02em;
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;

	@include media('<tablet') {
		font-size: 18px;
		line-height: 20px;
	}
}
.subtitle {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.02em;
	color: #455057;
	margin-bottom: 8px;
}
.security {
	padding: 7px 15px;
	gap: 4px;
	background: #ffffff;
	border: 1px solid #d0e5f8;
	border-radius: 4px;
	margin-bottom: 32px;

	&__node {
		display: flex;
		margin-bottom: 4px;

		& i {
			font-size: 24px;
			color: #98a1aa;
			background: #f8f9fb;
			border-radius: 4px;
		}
	}

	&__number {
		font-weight: 400;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: -0.02em;
		color: #3a86cb;
		margin-left: 8px;
		text-decoration: none;
	}

	&__subtitle {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #6b7881;
	}
}
