@import 'styles/utils/_include-media.scss';

.label {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.02em;
	color: #455057;
	display: inline-block;
}
.sublabel {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.02em;
	color: #455057;
	margin-bottom: 4px;
}
.selectWrapper {
	margin-bottom: 16px;
}
.data {
	display: flex;
	align-items: flex-start;
	padding: 11px 15px;
	gap: 10px;
	background: #ffffff;
	border: 1px solid #d0e5f8;
	border-radius: 4px;

	@include media('<tablet') {
		flex-direction: column;
		gap: 8px;
	}

	&__flag {
		width: 24px;
		max-width: 100%;
	}

	&__title {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #121f33;
		margin-bottom: 4px;
	}

	&__subtitle {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;
	}

	&__country {
		display: block;
	}
}
