.formControl {
	margin-bottom: 12px;
	width: 100%;

	@include media('>=tablet') {
		margin-bottom: 16px;
	}

	&__label {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;
		margin-bottom: 4px;
		display: inline-block;
	}

	&__wrap {
		position: relative;
	}

	&__input {
		background: #ffffff;
		border: 1px solid #d8dcdf;
		border-radius: 4px;
		padding: 10px 16px;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: -0.02em;
		color: #98a1aa;
		display: block;
		width: 100%;

		&:not(:placeholder-shown) {
			color: #121f33;
		}

		&:focus {
			border: 1px solid #4a93d6;
			color: #121f33;
			outline: none;
			-webkit-box-shadow: 0px 0px 0px 2px rgb(227, 239, 250);
			-moz-box-shadow: 0px 0px 0px 2px rgb(227, 239, 250);
			box-shadow: 0px 0px 0px 2px rgb(227, 239, 250);
		}

		&:disabled {
			background: #f8f9fb;
		}
	}

	&__errorBorder {
		border: 1px solid #de4548 !important;
	}

	&__error {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: #de4548;
	}
	&__btn-show {
		height: 40px;
		width: 40px;
		position: absolute;
		top: 0;
		right: 8px;
		background: none;
		margin: 0;
		border: none;
		color: #98a1aa;
		font-size: 24px;
		line-height: 40px;
		text-align: center;
	}

	&__textarea {
		max-height: 116px;
		min-height: 72px;
		resize: none;
		padding: 6px 14px;

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background-color: #d8dcdf;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb {
			background: #98a1aa;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: #6b7881;
		}
	}
}
