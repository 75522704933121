@import 'styles/utils/_include-media.scss';

.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@include media('<desktop') {
		justify-content: center;
	}
}
