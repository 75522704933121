@import 'src/styles/utils/include-media';

.link {
	display: block;
	height: 40px;
}
.image {
	height: 40px;
	object-fit: cover;

	@include media('>=desktop') {
		width: auto;
	}
}
