@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('././subsetMontserrat Light.woff2') format('woff2'),
		local('Montserrat'), url('./subsetMontserrat Light.woff') format('woff'),
		local('Montserrat'), url('./subsetMontserrat Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('./subsetMontserrat Regular.woff2') format('woff2'),
		local('Montserrat'), url('./subsetMontserrat Regular.woff') format('woff'),
		local('Montserrat'), url('./subsetMontserrat Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('./subsetMontserrat Italic.woff2') format('woff2'),
		local('Montserrat'), url('./subsetMontserrat Italic.woff') format('woff'),
		local('Montserrat'), url('./subsetMontserrat Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('./subsetMontserrat Medium.woff2') format('woff2'),
		local('Montserrat'), url('./subsetMontserrat Medium.woff') format('woff'),
		local('Montserrat'), url('./subsetMontserrat Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('./subsetMontserrat SemiBold.woff2') format('woff2'),
		local('Montserrat'), url('./subsetMontserrat SemiBold.woff') format('woff'),
		local('Montserrat'), url('./subsetMontserrat SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('./subsetMontserrat Bold.woff2') format('woff2'),
		local('Montserrat'), url('./subsetMontserrat Bold.woff') format('woff'), local('Montserrat'),
		url('./subsetMontserrat Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url('./subsetMontserrat Black.woff2') format('woff2'),
		local('Montserrat'), url('./subsetMontserrat Black.woff') format('woff'),
		local('Montserrat'), url('./subsetMontserrat Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
