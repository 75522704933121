@import 'src/styles/utils/include-media';

.wrapper {
	padding-top: 32px;
}
.notFound {
	text-align: center;
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: -0.02em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #121f33;
	height: calc(100vh - 72px);

	@include media('>=tablet') {
		padding-top: 0;
	}
}
.link {
	border-radius: 4px;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.02em;
	border: 1px solid #4a93d6;
	background: #fff;
	color: #4a93d6;
	padding: 7px 16px;
	display: flex;
	max-width: 144px;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	margin-top: 20px;

	@include media('>=tablet') {
		max-width: 168px;
		margin: 20px auto;
	}
	&__arrow {
		margin-left: 8px;
	}
}
.logo {
	margin: 0 auto;
	display: block;
}
