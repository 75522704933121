.progress {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-align: right;
	color: #455057;

	&__active {
		color: #519bde;
	}

	&__line {
		position: absolute;
		content: '';
		height: 4px;
		background: #519bde;
		bottom: -4px;
		left: 0;
		z-index: 1;
	}
}
.top {
	top: 0;
}
