$fontsDir: '../../assets/fonts/icomoon/';

@font-face {
	font-family: 'icomoon';
	src: url('#{$fontsDir}icomoon.woff?tz68l0') format('woff'),
		url('#{$fontsDir}icomoon.svg?tz68l0#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-sent:before {
	content: '\e922';
}
.icon-loading:before {
	content: '\e920';
}
.icon-upload:before {
	content: '\e921';
}
.icon-school:before {
	content: '\e91f';
}
.icon-phone-solid:before {
	content: '\e91e';
}
.icon-time:before {
	content: '\e91d';
}
.icon-arrow-down-bold:before {
	content: '\e91c';
}
.icon-info-circle:before {
	content: '\e91b';
}
.icon-dashboard:before {
	content: '\e91a';
}
.icon-edit:before {
	content: '\e919';
}
.icon-log-out:before {
	content: '\e917';
}
.icon-settings:before {
	content: '\e918';
}
.icon-apple:before {
	content: '\e900';
}
.icon-arrow-down:before {
	content: '\e901';
}
.icon-arrow-left:before {
	content: '\e902';
}
.icon-big-arrow-right:before {
	content: '\e903';
}
.icon-big-chevron-double-down:before {
	content: '\e904';
}
.icon-big-close:before {
	content: '\e905';
}
.icon-big-delete:before {
	content: '\e906';
}
.icon-big-eye-off:before {
	content: '\e907';
}
.icon-big-eye-on:before {
	content: '\e908';
}
.icon-big-menu:before {
	content: '\e909';
}
.icon-big-placeholder:before {
	content: '\e90a';
}
.icon-big-plus:before {
	content: '\e90b';
}
.icon-big-search:before {
	content: '\e90c';
}
.icon-check:before {
	content: '\e90d';
}
.icon-chevron-down:before {
	content: '\e90e';
}
.icon-chevron-left:before {
	content: '\e90f';
}
.icon-chevron-up:before {
	content: '\e910';
}
.icon-close:before {
	content: '\e911';
}
.icon-copy:before {
	content: '\e912';
}
.icon-info:before {
	content: '\e913';
}
.icon-minus:before {
	content: '\e914';
}
.icon-placeholder:before {
	content: '\e915';
}
.icon-plus:before {
	content: '\e916';
}
.icon-clock:before {
	content: '\e923';
}
