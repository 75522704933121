.desktop {
	background: #121f33;
	padding: 32px 0 10px;
	color: #fff;

	&__top {
		border-bottom: 1px solid #6b7881;
		display: flex;
		padding-bottom: 32px;
	}

	&__links {
		display: flex;
		width: 40%;
	}

	&__logo {
		width: 50%;
		width: 60%;
		& img {
			width: 20px;
		}
	}
	&__policy {
		width: 40%;
		display: flex;
		align-items: center;
	}

	&__part {
		margin-right: 70px;
		display: flex;
		flex-direction: column;
		min-width: 51px;

		&:last-child {
			margin-right: 0;
		}
	}

	&__title {
		font-weight: 600;
		font-size: 6px;
		line-height: 12px;
		letter-spacing: -0.02em;
		color: #ffffff;
		margin-bottom: 8px;
		display: inline-block;
	}

	&__footer {
		padding-top: 8px;
		display: flex;
	}

	&__list {
		display: flex;
		flex-direction: column;
	}

	&__copyright {
		font-weight: 500;
		font-size: 5px;
		line-height: 16px;
		color: #98a1aa;
		width: 60%;
	}

	&__link {
		font-weight: 400;
		font-size: 5px;
		line-height: 8px;
		color: rgba(255, 255, 255, 0.8);
		margin-bottom: 5px;
		text-decoration: none;
		display: inline-block;

		&:last-child {
			margin-right: 0;
		}
	}
}
