.block {
	padding: 68px 0;
	background: #fff;

	& h2 {
		max-width: 306px;
		margin: 0 auto;
		margin-bottom: 10px;
		font-weight: 600;
		letter-spacing: -0.02em;
		font-size: 20px;
		text-align: center;
		line-height: 22px;
	}

	&__subtitle {
		font-weight: 400;
		font-size: 7px;
		line-height: 11px;
		letter-spacing: -0.02em;
		color: #455057;
		max-width: 302px;
		text-align: center;
		margin: 0 auto;
		margin-bottom: 12px;
	}

	&__btn {
		padding: 7px 16px;
		display: flex;
		align-items: center;
	}

	&__arrow {
		margin-left: 8px;
		font-size: 10px;
	}
}
.link {
	border-radius: 4px;
	font-weight: 600;
	font-size: 6px;
	line-height: 10px;
	letter-spacing: -0.02em;
	border: 1px solid #4a93d6;
	background: #fff;
	color: #4a93d6;
	margin: auto;
	padding: 3px 8px;
	cursor: pointer;
	display: flex;
	max-width: 70px;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}
