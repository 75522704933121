.hero {
	background: #1a3f6b;
	display: flex;

	&__title {
		font-weight: 500;
		font-size: 20px;
		line-height: 25px;
		letter-spacing: -0.02em;
		margin-bottom: 6px;
		color: #fff;
		max-width: 274px;
	}

	&__subtitle {
		font-weight: 400;
		letter-spacing: -0.02em;
		color: rgba(255, 255, 255, 0.8);
		margin-top: 0;
		font-size: 8px;
		line-height: 12px;
		max-width: 252px;
	}

	&__container {
		padding: 54px 0px 118px;
		width: 100%;
		margin: 0 auto;
		padding-left: 36px;
		padding-right: 0;
		max-width: 820px;
	}
	&__school {
		text-align: center;

		& img {
			display: block;
			margin: 0 auto;
			margin-bottom: 6px;
			max-width: 40px;
		}
		display: flex;
		width: 27%;
		min-width: 150px;
		flex-direction: column;
		background: #121f33;
		align-items: center;
		justify-content: center;
	}

	&__schoolName {
		font-weight: 600;
		font-size: 6px;
		line-height: 12px;
		letter-spacing: -0.02em;
		color: rgba(255, 255, 255, 0.8);
	}
}
.arrow {
	background: #3a608d;
	text-align: center;

	padding: 32px 0 32px;

	&__content {
		padding-left: 21px;
		padding-right: 0;
		max-width: 820px;
		width: 100%;
		margin: 0 auto;
		text-align: left;
	}

	&__text {
		line-height: 16px;
		color: rgba(255, 255, 255, 0.8);

		display: block;
		font-weight: 600;
		font-size: 14px;
		letter-spacing: -0.02em;
		margin-bottom: 2px;
		letter-spacing: 0;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
	}

	& i {
		color: #fff;
		font-size: 24px;
	}
}
