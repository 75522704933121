@import 'src/styles/utils/include-media';

.copyright {
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #98a1aa;
	position: absolute;
	bottom: 24px;
	width: 288px;
	left: 50%;
	transform: translateX(-50%);

	@include media('>=tablet') {
		width: 352px;
	}
	@include media('>=desktop') {
		width: 360px;
	}
}
