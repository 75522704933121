$color: #252525;
$border: #bbbbbb;
$count-color: #818181;

.checkbox {
	overflow: hidden;
	position: relative;

	&.disabled {
		user-select: none;
		pointer-events: none;
		color: #d6d6d6;

		.checkbox__count {
			color: #d6d6d6;
		}
	}

	&__label {
		min-height: 16px;
		vertical-align: top;
		padding: 0 0 0 24px;
		position: relative;
		cursor: pointer;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: #455057;

		& a {
			color: #519bde;
			margin: 0 5px;
			text-decoration: none;
		}

		&.disabled {
			user-select: none;
			pointer-events: none;
			color: #d6d6d6;

			&:before {
				background: #e8e8e8;
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 14px;
			height: 14px;
			border: 1px solid #d8dcdf;
			border-radius: 4px;
			background: #fff;
		}

		&:after {
			content: '\e90d';
			font-family: 'icomoon' !important;
			font-size: 14px;
			position: absolute;
			top: 1px;
			left: 1px;
			color: #fff;
			display: none;
		}
	}

	&__count {
		color: $count-color;
		padding-left: 4px;
		line-height: 28px;
	}

	&__input {
		position: absolute;
		left: -9999px;
	}
}

.error {
	&:before {
		border: 1px solid #de4548;
	}
}
input:checked + .checked:after {
	display: block;
}

input:checked + .checked:before {
	background: #519bde;
}
input[type='checkbox']:focus + label:before {
	border: 1px solid #4a93d6;
	color: #121f33;
	outline: none;
	-webkit-box-shadow: 0px 0px 0px 2px rgb(227, 239, 250);
	-moz-box-shadow: 0px 0px 0px 2px rgb(227, 239, 250);
	box-shadow: 0px 0px 0px 2px rgb(227, 239, 250);
}
