.added {
	background: #e9fbef;
	border: 1px solid #3ebb22;
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	align-items: center;
	padding: 12px 16px;
	max-width: 360px;
	margin: 0 auto;
	margin-bottom: 12px;

	&__icon {
		width: 20px;
		height: 20px;
		object-fit: cover;
		margin-right: 12px;
	}

	&__text {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;

		& span {
			display: block;
		}
	}
}
.success {
	background: #e9fbef;
	border: 1px solid #3ebb22;
}
.info {
	background: #e9f2fb;
	border: 1px solid #3a86cb;
}
.error {
	background: #ffe3e9;
	border: 1px solid #de4548;
}
