.form_file_upload {
	min-height: 139px;
	width: 100%;
	text-align: center;
	position: relative;
}

.input_file_upload {
	display: none;
}

.label_file_upload {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 13px;
	gap: 8px;
	background: #ffffff;
	border: 2px dashed #d8dcdf;
	border-radius: 4px;
}

.label_file_upload.drag_active {
	background-color: #e9f2fb;
}

.upload_button {
	cursor: pointer;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.02em;
	color: #3a86cb;
	font-weight: 600;
	text-decoration-line: underline;
	border: none;
	background: none;
	padding: 0;
}

.drag_file_element {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}
.upload_icon {
	background: #e9f2fb;
	border-radius: 4px;
	padding: 8px;
	display: inline-block;
	cursor: pointer;
	margin-bottom: 8px;

	& i {
		color: #519bde;
		font-size: 24px;
	}
}
.label {
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	letter-spacing: -0.02em;
	color: #3a86cb;
	font-weight: 400;
	margin-bottom: 8px;
}
.define {
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #6b7881;
	max-width: 150px;
	margin: 0 auto;
}
.preview {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	&__picture {
		background: #ffffff;
		border: 2px dashed #d8dcdf;
		border-radius: 4px;
		width: 124px;
		height: 124px;
	}

	&__image {
		max-width: 100%;
		object-fit: contain;
		max-height: 100%;
	}

	&__remove {
		padding: 10px 28px;
		display: flex;
		align-items: center;
	}

	&__icon {
		font-size: 24px;
		margin-left: 8px;
	}
}
.error {
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #de4548;
}
