.circle-wrapper {
	position: relative;
}

.circle-background,
.circle-progress {
	fill: none;
}

.circle-background {
	stroke: #d0e5f8;
}

.circle-progress {
	stroke: #519bde;
	stroke-linecap: round;
	stroke-linejoin: round;
}

.circle-text {
	font-weight: 600;
	font-size: 36px;
	line-height: 44px;
	letter-spacing: -0.02em;
	color: #3a608d;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.circle-percent {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.02em;
	color: #3a608d;
	top: -17px;
}

@media (max-width: 768px) {
	.circle-text {
		font-size: 24px;
		line-height: 28px;
	}
	.circle-percent {
		font-size: 12px;
		line-height: 16px;
		top: -8px;
	}
}
