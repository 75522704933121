.preview {
	&__header {
		background: #f2f1f1;

		padding: 6px 13px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__domain {
		background: #ffffff;
		opacity: 0.85;
		border-radius: 4px;
		width: calc(100% - 58px);
		text-align: center;
		padding: 5px;
		color: #9b9b9b;
		font-size: 12px;
		font-weight: 700;
	}

	&__icons {
		width: 58px;
		display: flex;
		align-items: center;
	}

	&__icon {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		display: block;
		margin-right: 7px;
	}
}
