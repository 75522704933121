.container-lg {
	margin: 0 auto;
	@include media('>=desktop') {
		max-width: 744px;
	}
	@include media('>=tablet', '<desktop') {
		max-width: 352px;
	}
}
.container {
	padding: 0 16px;

	@include media('>=tablet') {
		padding: 0 24px;
	}
	@include media('>=desktop') {
		padding: 0 21px;
		max-width: 1170px;
		width: 100%;
		margin: 0 auto;
	}
}
.container-preview {
	padding: 0 38px;
}
.container-md {
	@include media('>=tablet') {
		max-width: 352px;
		padding: 0;
		margin: 0;
	}
	@include media('>=desktop') {
		max-width: 360px;
	}
}
.centered-container-md {
	@include media('<desktop') {
		margin: 0 auto;
		max-width: 352px;
		padding: 0;
	}

	@include media('<tablet') {
		padding: 0 16px;
	}
}

.container-md2 {
	padding: 0;
	@include media('>=tablet') {
		max-width: 352px;
		padding: 0;
		margin: 0;
	}
	@include media('>=desktop') {
		max-width: 360px;
	}
}

.container-xs {
	padding: 0 16px;
	@include media('>=tablet') {
		padding: 0;
	}
}

.step {
	&__form {
		padding: 44px 0 90px;
		min-height: calc(100vh - 144px);
		position: relative;
		@include media('>=desktop') {
			padding: 68px 0 90px;
		}
	}

	&__field {
		margin-bottom: 16px;
	}
	&__title {
		font-weight: 600;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #121f33;

		@include media('>=tablet') {
			font-size: 24px;
			line-height: 28px;
		}
	}

	&__subtitle {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;
		margin-top: 8px;
	}

	&__titles {
		margin-bottom: 16px;

		@include media('>=tablet') {
			margin-bottom: 24px;
		}
	}

	&__desktopGrid {
		@include media('>=desktop') {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: auto;
			grid-column-gap: 24px;
		}
	}

	&__md_mt38 {
		@include media('>=desktop') {
			margin-top: 38px;
		}
	}

	&__center {
		max-width: 360px;
		margin: 0 auto;
	}

	&__checkbox {
		display: flex;
		margin-top: 16px;
		@include media('>=desktop') {
			margin-top: 0px;
			align-self: baseline;
		}
	}

	&__checkboxText {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		display: flex;
		align-items: center;
		color: #455057;

		& a {
			color: #519bde;
			margin: 0 5px;
			text-decoration: none;
		}
	}
}

.step-buttons {
	position: fixed;
	bottom: 0;
	width: 100%;
	left: 0;
	background: #ffffff;
	box-shadow: inset 0px 1px 0px #d8dcdf;

	&__wrapper {
		padding: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include media('>=desktop') {
			padding: 16px 21px;
			max-width: 1170px;
			width: 100%;
			margin: 0 auto;
		}
	}

	&__wrapperRight {
		padding: 16px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		@include media('>=desktop') {
			padding: 16px 21px;
			max-width: 1170px;
			width: 100%;
			margin: 0 auto;
		}
	}

	&__backBtn {
		display: flex;
		align-items: center;
		padding: 8px 16px 8px 4px !important;

		& i {
			font-size: 24px;
			margin-right: 4px;
		}
	}

	&__next {
		min-width: 97px;
	}

	&__confirm {
		min-width: 90px;
	}
}
.step-field-wrapper {
	margin-bottom: 16px !important;
	@include media('>=tablet') {
		margin-bottom: 24px !important;
	}
}
.field {
	&__mb24 {
		margin-bottom: 24px;
	}
}
