.header {
	box-shadow: 0px 1px 0px #d8dcdf;
	position: relative;
	background: #fff;
	height: 30px;
	padding: 7px 0px;

	&__image {
		height: 20px;
		object-fit: cover;
	}
	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__right {
		display: flex;
		align-items: center;
	}

	&__menu {
		margin-left: 24px;
		width: 24px;
		height: 24px;
		background: none;
		border-radius: 3px;
		padding: 7px 4px;
		border: none;
		position: relative;

		& span {
			display: block;
			line-height: 3px;
			width: 16px;
			height: 3px;
			border-radius: 2px;
			background: #98a1aa;

			&:last-child {
				margin-top: 6px;
			}
		}
	}

	&__logoLink {
		display: block;
		height: 40px;
	}
}
.menu {
	display: flex;
	align-items: center;

	&__part {
		margin-right: 16px;
		position: relative;
		height: 20px;
	}

	&__title {
		font-weight: 400;
		font-size: 8px;
		letter-spacing: -0.02em;
		color: #455057;
		text-align: center;
		line-height: 20px;
		background: none;
		border: none;
		display: flex;
		align-items: center;
		padding: 0;
		text-decoration: none;

		& i {
			margin-left: 4px;
		}
	}
	&__btn {
		padding: 2px 5px;
		text-align: center;
		font-weight: 600;
		font-size: 7px;
		line-height: 12px;
	}
}
