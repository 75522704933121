@import 'src/styles/utils/include-media';

.header {
	padding: 16px 0;
	box-shadow: 0px 1px 0px #d8dcdf;
	position: relative;
	background: #fff;

	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__right {
		display: flex;
		align-items: center;

		& button {
			@include media('>=tablet') {
				order: 1;
			}
		}
	}

	&__menu {
		margin-left: 24px;
		width: 24px;
		height: 24px;
		background: none;
		border-radius: 3px;
		padding: 7px 4px;
		border: none;
		position: relative;

		& span {
			display: block;
			line-height: 3px;
			width: 16px;
			height: 3px;
			border-radius: 2px;
			background: #98a1aa;

			&:last-child {
				margin-top: 6px;
			}
		}
	}
}
