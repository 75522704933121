@mixin border {
	background: #ffffff;
	border: 1px solid #d8dcdf;
	border-radius: 4px;
}

.form {
	width: 360px;

	&__logo {
		@include border;
		padding: 14px;
		gap: 14px;
		margin-bottom: 16px;
	}

	&__title {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #121f33;
		margin-bottom: 4px;
	}

	&__subtitle {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #455057;
		margin-bottom: 16px;
	}

	&__content {
		@include border;
	}
	&__section {
		padding: 16px;
		padding-top: 20px;
		gap: 14px;
		border-bottom: 1px solid #d8dcdf;

		&Title {
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;
			color: #519bde;
			margin-bottom: 8px;
		}

		&:last-child {
			border-bottom: none;
		}
		&:first-child {
			padding-top: 16px;
		}
	}
	&__input {
		margin-bottom: 0;
	}
}
