.block {
	background: #1a3f6b;
	padding: 76px 0 82px;

	&__title {
		margin-bottom: 26px;
		color: #fff;
		max-width: 350px;
		letter-spacing: -0.02em;
	}

	&__link {
		font-weight: 600;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #fff;
		margin-bottom: 18px;
		display: inline-block;
	}

	&__items {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: repeat(4, 1fr);
	}

	&__item {
		background: #3a608d;
		border-radius: 4px;
		color: #fff;
		padding: 8px 10px;
		position: relative;
		aspect-ratio: 1;
		display: flex;
		align-items: flex-end;
	}

	&__number {
		font-weight: 600;
		font-size: 10px;
		line-height: 14px;
		position: absolute;
		top: 10px;
		left: 10px;
	}

	&__image {
		width: 30px;
		height: 30px;
		object-fit: cover;
		margin-bottom: 4px;
		display: inherit;
	}

	&__p {
		font-weight: 600;
		font-size: 8px;
		line-height: 12px;
		letter-spacing: -0.02em;
		color: rgba(255, 255, 255, 0.8);
		max-width: 80px;
	}
}
