.field {
	display: flex;
	align-items: center;
	padding: 6px 14px;
	gap: 2px;
	height: 52px;
	background: #ffffff;
	border: 1px solid #d8dcdf;
	border-radius: 4px;
	margin-bottom: 24px;

	&__icon {
		margin-right: 12px;
		color: #6b7881;
		font-size: 24px;
	}

	&__school {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #6b7881;
	}

	&__name {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
		color: #121f33;
	}

	&__block {
		display: block;
	}
}
