@import 'src/styles/utils/include-media';

.block {
	padding: 48px 95px;
	background: #ffffff;
	border: 1px solid #d0e5f8;
	box-shadow: 0px 2px 4px rgba(216, 220, 223, 0.7);
	border-radius: 16px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	@include media('<tablet') {
		padding: 32px 15px;
	}

	@include media('>=tablet', '<desktop') {
		padding: 48px 91px;
	}

	&__title {
		font-weight: 600;
		font-size: 24px;
		line-height: 28px;
		text-align: center;
		letter-spacing: -0.02em;
		color: #121f33;
		margin-bottom: 8px;

		@include media('<tablet') {
			font-size: 18px;
			line-height: 20px;
		}
	}

	&__subtitle {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		letter-spacing: -0.02em;
		color: #6b7881;
		margin-bottom: 20px;
	}
	&__progress {
		height: 172px;

		@include media('<tablet') {
			height: 120px;
		}
	}

	&__wrapper {
		text-align: center;
	}
}
.done {
	position: relative;
	margin-bottom: 24px;
	padding-top: 5px;

	&__bg {
		max-width: 140px;

		@include media('<tablet') {
			max-width: 104px;
		}
	}

	&__icon {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-size: 80px;
		color: #3ebb22;

		@include media('<tablet') {
			font-size: 48px;
		}
	}
	&__failed {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		font-size: 80px;
		color: #fa7f0e;

		@include media('<tablet') {
			font-size: 48px;
		}
	}
}
.button {
	padding: 10px 16px;
	background: #3ebb22;
	border-radius: 4px;
	color: #fff;
	text-decoration: none;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	letter-spacing: -0.02em;
	display: inline-block;
	margin: auto;
}
