.wrapper {
	background: #f8f9fb;
	padding: 74px 0px 62px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}
.text {
	width: 50%;
	padding-right: 38px;
	&__title {
		font-size: 15px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.02em;
		color: #121f33;
		margin-bottom: 5px;
	}

	&__subtitle {
		font-weight: 400;
		font-size: 8px;
		line-height: 11px;
		letter-spacing: -0.02em;
		color: #455057;
	}
}

.extra_wrapper {
	width: 49%;
}

.extra {
	border: 1px solid #d8dcdf;
	border-radius: 4px;
	padding: 8px;
	margin-bottom: 12px;

	&__paid {
		display: flex;
		flex-direction: column;
	}
	&__info {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 5px;
	}
	&__cost {
		width: 40px;
		padding: 2px 4px;
		gap: 8px;
		background: #e9f2fb;
		border-radius: 4px;
		font-weight: 500;
		font-size: 7px;
		line-height: 10px;
		color: #519bde;
	}
	&__currency {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #121f33;
		vertical-align: 70%;

		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
	}

	&__price {
		font-weight: 600;
		font-size: 36px;
		line-height: 36px;
		letter-spacing: -0.05em;
		color: #121f33;
		display: inline-block;
		margin-right: 4px;

		font-size: 48px;
		line-height: 46px;
	}

	&__quantity {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #6b7881;
		display: inline-block;
		max-width: 32px;

		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.02em;
	}

	&__trigger {
		color: #6b7881;
		font-weight: 400;
		font-size: 6px;
		line-height: 18px;
		letter-spacing: -0.02em;
		padding-right: 30px;
	}

	&__subtext {
		font-weight: 600;
		font-size: 7px;
		line-height: 12px;
		letter-spacing: -0.02em;
		color: #6b7881;
	}

	&__prepaid {
		font-weight: 600;
		font-size: 11px;
		line-height: 11px;
		letter-spacing: -0.02em;
		color: #121f33;
	}
}
.counter {
	background: #ffffff;
	border: 1px solid #d8dcdf;
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-width: 44px;
	width: -moz-fit-content;
	width: fit-content;
	padding: 1px 2px;
	column-gap: 0px;

	& button {
		color: #98a1aa;
		padding: 0;
		background: none;
		font-size: 8px;
		border: none;
		width: 10px;

		&:disabled {
			color: #d8dcdf;
		}
	}

	&__count {
		text-align: center;
		padding: 0 2px;
		font-weight: 600;
		font-size: 8px;
		min-width: 12px;
		line-height: 14px;
		letter-spacing: -0.02em;
		color: #121f33;
	}
}
.button {
	font-size: 6px;
	padding: 1px 2px;
	line-height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;

	& i {
		font-size: 14px;
		margin-left: 5px;
	}
}
.block {
	width: max-content;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	.currency {
		font-weight: 500;
		font-size: 6px;
		line-height: 6px;
		color: #121f33;
		top: -1.6em;
	}

	.price {
		font-weight: 600;
		font-size: 20px;
		line-height: 18px;
		letter-spacing: -0.05em;
		color: #121f33;
		display: inline-block;
		margin-right: 4px;
	}

	.quantity {
		font-weight: 500;
		font-size: 6px;
		line-height: 8px;
		color: #6b7881;
		display: inline-block;
		max-width: 16px;
	}
	.quantity_more {
		font-weight: 500;
		font-size: 6px;
		line-height: 8px;
		color: #6b7881;
		display: inline-block;
		max-width: 25px;
	}
}
.field {
	height: 18px;
	display: block;
	width: 100%;
	background: white;
	border-radius: 4px;
	border: 1px #d8dcdf solid;
	color: #98a1aa;
	font-size: 8px;
	font-family: 'Montserrat';
	font-weight: 400;
	line-height: 20px;
	padding: 3px 6px;
	word-wrap: break-word;
}
.label {
	color: #455057;
	font-size: 8px;
	font-family: 'Montserrat';
	font-weight: 600;
	line-height: 20px;
	word-wrap: break-word;
}
.fieldWrapper {
	margin-bottom: 10px;
}
