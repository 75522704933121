@import 'src/styles/utils/include-media';

.wrapper {
	max-width: 552px;
	padding: 32px 0;
	margin: 0 auto;

	@include media('<tablet') {
		padding: 32px 16px;
	}
	@include media('>=tablet', '<desktop') {
		max-width: 536px;
		padding: 48px 0;
	}
}
.logo {
	margin: 0 auto 96px;
	display: block;
}
