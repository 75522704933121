@import 'styles/utils/_include-media.scss';

.preview {
	max-width: 552px;
	width: 100%;
	border-radius: 4px 4px 0px 0px;
	border-width: 1px;
	border-style: solid;
	border-color: #d8dcdf;

	@include media('<desktop') {
		display: none;
	}
}
.red {
	background: #e96e4c;
}
.orange {
	background: #e6a935;
}
.green {
	background: #85c33d;
}
